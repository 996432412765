import React from "react";

import { mount } from "utils";
import { PostCard, PostList } from "comps/post";
import { PageFixed } from "comps/page";

export const getApp = ({ posts }) => {
  const App = () => {
    return (
      <PostList>
        {posts.map((p) => (
          <PostCard key={p.id} post={p} />
        ))}
        <PageFixed />
      </PostList>
    );
  };
  return App;
};

if (!window.IS_SSR) {
  const App = getApp(window.REACT_CONTEXT);
  mount(App, "main");
}
